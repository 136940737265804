#table .header-column {
    background-color: #FAFAFA;
    font-weight: bold;
    text-align: center;
}

body.resizing {
    user-select: none;
}

.sorting-icon {
    background: url('../../../public/filter_dsc.svg');
    height: 20px;
    width: 18px;
    margin-left: 5px;
    transition: transform 0.3s ease;
    /* Add transition for smooth rotation */
}

.sorting-asc-icon {
    transform: rotate(180deg);
}

/* Table default theme */
.ReactVirtualized__Table__headerRow {
    font-weight: bold;
    font-size: 14px;
    font-family: Roboto;
    color: #000000;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    border-bottom: 3px solid #707070;

}

.ReactVirtualized__Table__row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    border-bottom: 1px solid #CCCCCC;
}

.ReactVirtualized__Table__row__expanded {
    border-bottom: none !important;
}

.ReactVirtualized__Table__headerTruncatedText {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ReactVirtualized__Table__rowColumn {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ReactVirtualized__Table__sortableHeaderColumn {
    cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.ReactVirtualized__Table__sortableHeaderIcon {
    -webkit-box-flex: 0;
    flex: 0 0 24px;
    height: 1em;
    width: 1em;
    fill: currentColor;
}

.basic_cell_content {
    font-size: 16px;
    color: #707070;
    font-family: Roboto;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.expanded_cell_content {
    font-size: 16px;
    color: #FFFFFF;
    font-family: Roboto;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.left_alligned_text {
    text-align: left;
}

.right_alligned_text {
    text-align: right;
}

.labels_cell_content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px !important;
    line-height: 10px !important;
}

/*Kaj naredimo v primeru, ko je seja v napaki in zaklenjena? Kaj je mišljeno s stanje "V UREJANJU"?*/
/*Enako velja za canceled*/
.state_cell {
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.canceled_session_expanded {
    color: #ffc5c5;
}

.canceled_session {
    color: #F80534;
}

.deleted_session {
    color: #BFBFBF;
}

.error_session_expanded {
    color: #ffc5c5;
}

.error_session {
    color: #F80534;
}

.finished_session {
    color: #2B8DFF;
}

.finished_session_expanded {
    color: #d2e6fc;
}

.initializing_session {
    color: #606060;
}

.in_progress_session {
    color: #000000;
}

.in_queue_session {
    color: #404040;
}

.ReactVirtualized__Table__Grid {
    scroll-behavior: smooth;
}

.ReactVirtualized__Table {
    overflow: auto;
}

.detail_title {
    font-size: 12px;
    color: #707070;
    font-weight: bold;
    font-family: Roboto;
}

.preview-contents {
    background: #D9D9D9;
    box-shadow: 0px 0px 4px 0px #00000040 inset;
    width: 95%;
    padding: 6px 9px;
}

.preview-contents-text {
    color: #707070;
    font-family: Roboto Mono;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    white-space: normal;
    margin: 0;
}

.version-option {
    font-family: Roboto !important;
    color: #2B8DFF !important;
    font-size: 14px !important;
}

.version-tooltip {
    z-index: 10 !important;
}

.recordings-table-wrapper {
    flex: 1;
}

.recordings-table {
    width: 100%;
    border-spacing: 4px;
}

.recordings-table-thead {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;

}

.recordings-table-tbody {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;
}

.recordings-table-header-cell {
    padding: 0 4px;
}

.recordings-table-cell {
    padding: 0 4px;
}

.download-button {
    background: url('../../../public/download.svg');
    height: 24px;
    width: 24px;
    padding-right: 5px;
}

.share-button {
    background: url('../../../public/share.svg');
    height: 24px;
    width: 24px;
    padding-right: 5px;
}

.recreate-button {
    height: 24px;
    width: 24px;
    padding-right: 5px;
}

.cancel-session-button {
    height: 24px;
    width: 24px;
    padding-right: 5px;
}

.copy-button {
    background: url('../../../public/content_copy.svg');
    height: 24px;
    width: 24px;
    padding-right: 5px;
}

.delete-button {
    background: url('../../../public/delete.svg');
    height: 24px;
    width: 24px;
    padding-right: 5px;
}

.manage-labels-button {
    background: url('../../../public/add_more.svg') no-repeat center;
    height: 24px !important;
    width: 24px !important;
}

#table-scroll-container {
    /*overflow: scroll;*/
    box-sizing: border-box;

    scrollbar-color: #2B8DFF #CCCCCC;
}


.header-item-resizable-handle {
    width: 15px;
    height: 21px;
    cursor: col-resize;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-item-resizable-handle:hover {
    opacity: 1;
}

.header-item-resizable-handle>div {
    width: 2px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.87);
    cursor: col-resize;
}



.recording-info-tooltip {
    position: relative;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.recording-info-tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: rgb(112, 112, 112);
    color: rgb(255, 255, 255);
    border-radius: 6px;
    padding: 6px 12px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.recording-info-tooltip:hover .tooltiptext {
    visibility: visible;
}

.config-short {
    text-align: right;
}

.header-operation-button:hover {
    opacity: 0.2;
}

.header-operation-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}